import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Обо мне`}</h1>
    <p>{`Меня зовут Таня (иногда Танья 😄 ), мне почти 35 лет, трое детей, один муж, одна кошка и пару паучков в коридоре.
Родилась в Молдове, училась, жила, росла в Украине, последние 5 лет живу в Германии.`}</p>
    <p>{`С переездом что-то в моем восприятии к жизни, к системе, к окружению поменялось и я не хотела дальше работать по профессии финансы. Очень долго писать как так произошло, но я "вошла" в удивительный и яркий мир фотографии. От "я учусь" до "моя работа стоит..." прошло года три. За это время я постаралась найти свой стиль, я определилась с аудиторией, я сознательно не хочу пользоваться фотошопом, я знаю, как нажимать кнопочку на фотике и что такое ISO 😅`}</p>
    <p>{`Я - `}<strong parentName="p">{`фотограф`}</strong>{`. Звучит так громко и красиво, что от волнения вспотели ладошки 😄. Я - фотограф без правил. Постараюсь просто и ясно объяснить что это значит. Если вы хотите, глядя на свои фото, вспоминать фотостудии, процесс съёмки и мыслями как быть на фото теми, кем вы не являетесь на самом деле в арендованной одежде и профессиональном макияже, то это не ко мне.
Есть много прекрасных фотографов, которые возьмутся за такую сложную для меня работу. Я - за мгновения, ваши мгновения! Я - за естественность, я - за шум на фото, я - за искренние улыбки, я - за смазанную руку, я - за такую милую девичью стеснительнось, за детские капризы, за мужские ужимки, за сексуальность без пошлости, за прыжки, за развевающийся на ветру волосы, за заваленный горизонт, за обнимашки, за красоту естественную вашего тела, за эксперименты, за засветы, пересветы, за баловство перед камерой, за нестандартные ракурсы и расфокус,я - за смелость и стеснение, которое сменяется расслабленностью.
Я - за то, чтобы глядя на фото, вы видели вас, своих детей, своих жён, парней, собак, родителей с их недостатками и достоинствами, настоящими в настроении, которое было в момент щелчка затвора камеры.
Я не гонюсь за словом "идеально", но я только за естественность и память,потому что #ятаквижу Вы со мной? Пишите, будем работать!`}</p>
    <p>{`С уважением, Таня ❤`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      